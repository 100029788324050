.PollingWrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    flex-grow: 1;
    border-radius: 1%;
}

.PollingQuestionTextWrapper {
    margin-top: calc(8px + 0.05vmin);
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(5px + 0.15vmin) black;
    color: var(--extremely-light-background-color);
    text-align: center;
    text-shadow: 0px 0px calc(1px + 0.025vmin) var(--extremely-light-background-color);
    padding: calc(4px + 0.15vmin);
    border-top-left-radius: calc(4px + 0.10vmin);
    border-top-right-radius: calc(4px + 0.10vmin);
    white-space: pre-wrap;
    max-height: 15vh;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 0;
}

.PollingQuestionFieldOuterWrapper {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;;
}

.PollingQuestionFieldInnerWrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: calc(5px + 0.05vmin);
    padding-top: calc(3px + 0.05vmin);
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
    margin-bottom: calc(3px + 0.05vmin);
}

.PollingWrapper .QuestionField {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid black;
    padding: 0.5vmin;
    margin: 1%;
    line-height: normal;
    word-wrap: break-word;
    text-align: left;
    
}

.PollingWrapper .QuestionField > div:nth-child(2) {
    width: 90%;
}

.PollingWrapper .QuestionField > div:nth-child(1) {
    flex-grow: 1;
}

.PollingSubmitButton {
    min-height: 5vh;
    max-height: 5vh;
    width: 100%;
    margin: 0 !important;
    border: 1px solid var(--dark-background-color) !important;
}