* {
	--normal-background-color: #404040;
	--lighter-background-color: #4e4e4e;
	--light-background-color: #606060;
	--very-light-background-color: #858585;
	--very-very-light-background-color: #bfbfbf;
	--extremely-light-background-color: #f3f3f3;
	--darker-background-color: #303030;
	--dark-background-color: #222222;
	--very-dark-background-color: #191919;
	--primary-color: #0985cf;
	--primary-color-alpha: #0985cfaa;
	--light-primary-color: #0ea7ff;
	--light-primary-color-alpha: #0ea7ffaa;
	--very-light-primary-color: #bbdbff;
	--secondary-color: #09cf52;
	--dark-secondary-color: #00af32;
	--dark-white-color: #aaaaba;
	--white-color: #dddde5;
	--bright-white-color: #f3f3f3ff;
	--very-dark-white-color: #888890;
	--error-color: #cf1d09;
	--active-color: #09cf52;
	--dark-active-color: #089439;
	--light-active-color: #0ce357; 
	--inactive-color: #cf1d09;
	--light-inactive-color:#e81d06;
	--very-light-inactive-color:#d4a4a4;
	--warning-color: #dbbb07;
	--light-warning-color: #ffda08;
	--very-light-warning-color: #ffec7d;
	--dark-inactive-color: #851104;
	font-family: 'Mada', sans-serif;
	box-sizing: border-box;
	font-size: calc(1.525vmin);
}

option {
	color: black;
	text-shadow: 0 0 0 black;
}

.BrushedMetalGradient {
    background-color: black;
    background-image:
        repeating-linear-gradient(to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 25%, 0.5) 0.25%),
        repeating-linear-gradient(to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 20%, 0.7) 0.35%),
        repeating-linear-gradient(to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 30%, 0.7) 0.30%),
        repeating-linear-gradient(to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 10%, 0.7) 0.20%),
        repeating-linear-gradient(to right, hsla(0, 0%, 20%, 0.7), hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 20%, 0.7) 50%);
}


body {
	background-color: black;
	overflow: hidden;
}

.TransparentButton:first-child {
    border-left: calc(1px + 0.15vmin) solid var(--darker-background-color);
}

.TransparentButton:last-child {
	border-right: calc(1px + 0.15vmin) solid var(--darker-background-color);
}

.TransparentButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	text-align: center;
	flex: 1;
	line-height: 100%;
    font-weight: 400;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 0.15vmin;
    background: 
        url('../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--extremely-light-background-color), var(--very-light-background-color));
        /*radial-gradient(white, var(--extremely-light-background-color) 50%);*/
    background-repeat: repeat;
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--normal-background-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 rgba(0,0,0,0.25),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 rgba(0,0,0,0.15),
        calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.15vmin) black;
	border-top: calc(1px + 0.15vmin) solid var(--darker-background-color);
	border-bottom: calc(1px + 0.15vmin) solid var(--darker-background-color);
	border-left: calc(1px + 0.03vmin) solid var(--darker-background-color);
    border-right: calc(1px + 0.03vmin) solid var(--darker-background-color);
    color: var(--dark-background-color);
    text-shadow: 0px 1px 0px rgba(255,255,255,0.3), 0px -1px 0px rgba(0,0,0,.7);
	transition: 0.5s;
}

.TransparentButton.Static {
    transition: 0.25s;
}

.TransparentButton.active, .TransparentButton:active {
    filter: brightness(130%) !important;
    border-color: var(--dark-background-color);
    box-shadow:
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 rgba(0,0,0,0.25),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 rgba(0,0,0,0.25),
        inset 0 calc(2px + 0.30vmin) calc(10px + 0.75vmin) 0 var(--dark-background-color) !important;
}

.TransparentButton:hover {
    filter: brightness(110%);
}

.TransparentButton.BlueHaze.Static, .TransparentButton.BlueHaze:hover {
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--light-primary-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--very-light-primary-color));
}

.TransparentButton.BlueHaze.active, .TransparentButton.BlueHaze:active {
    background: 
        url('../Assets/BackgroundNoise.png'),
        radial-gradient(var(--light-primary-color) 25%, var(--light-primary-color));
}

.TransparentButton.RedHaze.Static, .TransparentButton.RedHaze:hover {
    box-shadow:
        inset 0 4px calc(6px + 4vmin) var(--light-inactive-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--very-light-inactive-color));
}

.TransparentButton.RedHaze.active, .TransparentButton.RedHaze:active {
    background: 
        url('../Assets/BackgroundNoise.png'),
        radial-gradient(var(--very-light-inactive-color), var(--light-inactive-color));
}

.TransparentButton.GreenHaze.Static, .TransparentButton.GreenHaze:hover {
    box-shadow:
        inset 0 4px calc(6px + 4vmin) var(--light-active-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--light-active-color));
}

.TransparentButton.GreenHaze.active, .TransparentButton.GreenHaze:active {
    background: 
        url('../Assets/BackgroundNoise.png'),
        radial-gradient(var(--light-active-color), var(--light-active-color));
}

.TransparentButton.inactive {
    filter: brightness(50%) !important;
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--normal-background-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color) !important;
    background: 
        url('../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--extremely-light-background-color), var(--very-light-background-color)) !important;
}

/*
* {
	--normal-background-color: #403f47;
	--lighter-background-color: #4e4e57;
	--light-background-color: #60606b;
	--very-light-background-color: #858494;
	--very-very-light-background-color: #bfbdd1;
	--extremely-light-background-color: #f3f3f3ff;
	--darker-background-color: #303036;
	--dark-background-color: #222226;
	--very-dark-background-color: #19191c;
	--primary-color: #0985cf;
	--light-primary-color: #0ea7ff;
	--very-light-primary-color: #bbdbff;
	--secondary-color: #09cf52;
	--dark-secondary-color: #00af32;
	--dark-white-color: #aaaaba;
	--white-color: #dddde5;
	--bright-white-color: #f3f3f3ff;
	--very-dark-white-color: #888890;
	--error-color: #cf1d09;
	--active-color: #09cf52;
	--dark-active-color: #089439;
	--light-active-color: #0ce357; 
	--inactive-color: #cf1d09;
	--light-inactive-color:#e81d06;
	--very-light-inactive-color:#d4a4a4;
	--warning-color: #dbbb07;
	--light-warning-color: #ffda08;
	--dark-inactive-color: #851104;
	font-family: 'Mada', sans-serif;
	box-sizing: border-box;
	font-size: calc(1.525vmin);
}*/

/*
@keyframes primary-text-glow {
	from {
		text-shadow: 0 0 calc(16px + 1vmin) var(--light-primary-color), 0 0 calc(40px + 3vmin) var(--primary-color);
		color: var(--very-light-primary-color);
	}
	to {
		color: var(--very-very-light-background-color);
	}
}

@keyframes primary-background-glow {
	from {
		box-shadow: 0 0 calc(16px + 1vmin) var(--light-primary-color), 0 0 calc(40px + 3vmin) var(--primary-color);
		background-color: var(--very-light-primary-color);
	}
	to {
		background-color: var(--very-very-light-background-color);
	}
}
*/
/* For switching between animations to synchronize things */
/*
@keyframes primary-background-glow-alt {
	from {
		box-shadow: 0 0 calc(16px + 1vmin) var(--light-primary-color), 0 0 calc(40px + 3vmin) var(--primary-color);
		background-color: var(--very-light-primary-color);
	}
	to {
		background-color: var(--very-very-light-background-color);
	}
}*/



.custom-active-button {
	background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
	background-color: black;
	color: var(--bright-white-color);
	border: none;
}

.custom-active-button:hover {
	filter: brightness(120%);
}

.custom-active-button:active {
	filter: brightness(85%);
	transform: translateY(2px);
}

.large-label {
	font-size: calc(2.54vmin);
	color: var(--white-color);
	text-overflow: ellipsis;
}

.centered-text {
	text-align: center;
}

.error-font {
	font-size: calc(1.225vmin);
	color: var(--error-color);
}

/*.Body {
	min-height: 720px;
	min-width: 1280px;
}*/

#root {
	position: absolute;
	overflow: hidden;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	min-width: 960px;
	min-height: 540px;
}

#scroller {
    visibility: hidden;
    position: relative;
    z-index: 10;
    left: 0;
    width: 1px;
    height: 9999999999999999999999px;
}

.ConnectionFailure {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: var(--active-color);
	width: 100vw;
	--section-width: 30vmin;
	--section-height: 40vmin;
	--section-padding: 0.5vmin;
	--section-border: calc(1px + 0.03vmin);
}

.ConnectionFailure div {
	font-size: calc(1.602vmin);
}


.CenteredSection {
	position: fixed;
	top: calc((100vh - var(--section-height))/2);
	left: calc((100vw - var(--section-width))/2);
	width: calc(var(--section-width) - 2 * var(--section-padding) - 2 * var(--section-border));
	height: calc(var(--section-height) - 2 * var(--section-padding) - 2 * var(--section-border));
	padding: var(--section-padding);
	border: var(--section-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--light-background-color), var(--very-light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(20px + 2.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 12;
}

.CenteredSection:before {
	position: absolute;
	content: '';
	box-sizing: border-box;
	width: calc(var(--section-width) - 2 * var(--section-padding) - 2 * var(--section-border));
	height: calc(var(--section-height) - 2 * var(--section-padding) - 2 * var(--section-border));
	border: var(--section-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: 11;

}

.NoiseBackground {
	background: url('../Assets/BackgroundNoise.png'),
		linear-gradient(to bottom right, var(--darker-background-color), var(--dark-background-color), var(--very-dark-background-color));
	background-repeat: repeat;
}


*::-webkit-scrollbar {
	width: calc(8px + 0.15vmin);
	background-color: black;
	background-image: linear-gradient(to top, var(--light-background-color), var(--very-light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 1.25vmin) black,
				inset calc(2px - 0.1vmin) calc(2px + -0.1vmin) calc(5px + 0.25vmin) var(--dark-background-color);
    border-left: 1px solid var(--lighter-background-color);
}

*::-webkit-scrollbar-thumb {
	border-radius: 10vmin;
	border: 1px var(--light-primary-color-alpha);
	background-image: linear-gradient(to bottom right, var(--primary-color-alpha), var(--light-primary-color), var(--light-primary-color), var(--primary-color-alpha));
}
