.MediaPanelWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
}

.LocalVideoWrapper {
    position: relative;
    /*flex-basis: 45%;*/
    align-self: center;
    width: 100%;
    height: 15vh;
}

.IsProcessingWrapper {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
}

.IsProcessingGraphic {
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
    background-color: #00000088;
    width: 10vh;
    height: 10vh;
    animation-name: pulsateProcessingBorder;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    border-radius: 100% 100%;
}

.IsProcessingGraphicInner {
    animation-name: pulsateConnectColor;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    font-size: 1.2em;
    text-align: center;
}

@keyframes pulsateProcessingBorder {
    0% {
        border: 5px solid var(--warning-color);
    }
    50% {
        border: 5px solid var(--primary-color);
    }
    100% {
        border: 5px solid var(--warning-color);
    }
}

@keyframes pulsateProcessingColor {
    0% {
        color: var(--warning-color);
    }
    50% {
        color: var(--primary-color);
    }
    100% {
        color: var(--warning-color);
    }
}
.LocalVideo {
    position: absolute;
    object-fit: contain;
    top: 4%;
    left: 2%;
    width: 96%;
    height: 92%;
    padding: 0.5vmin;
    background-color: black;
    border: 1px solid black;
    border-radius: 0.3vmin;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.LocalVideoWrapper .VideoFraming {
    position: absolute;
    top: 4%;
    left: 2%;
    width: 96%;
    height: 92%;
    padding: 0.5vmin;
    object-fit: contain;
	filter: hue-rotate(220deg) brightness(250%);
}

.SettingsMenuPresentationWrapper {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 5;
}

.FillElement {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 40%;
    background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.SettingsMenuPresentationWrapperOuter {
    align-self: center;
    flex-grow: 1;
    padding: 0.5vmin;
    padding-bottom: 1.0vmin;
    width: 100%;
    border: 1px solid black;
    border-radius: 0.2vmin;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.SettingsMenuPresentationWrapperInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    flex-basis: 20vh;
}

.SettingsMenuPresentationWrapperInner > select {
    flex-grow: 1;
    width: 100%;
    margin: 1%;
	border: none;
    outline: none;
	box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(10px + 0.375vmin) black;
    background: 
        url('../../../Assets/BackgroundNoise.png'),    
        linear-gradient(to bottom, var(--light-background-color), var(--lighter-background-color));
    color: var(--extremely-light-background-color);
    text-shadow: 0px 1px 0px rgba(0,0,0,0.3), 0px -1px 0px rgba(0,0,0,.7);
    font-weight: 300;
    padding: 0.8vmin;
}

.SettingsMenuButtonWrapper {
    display: flex;
    flex-basis: 15%;
    width: 100%;
    justify-content: space-evenly;
}

.AudioConfigWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    color: white;
    font-weight: 300;
}

.AudioConfigWrapper > div:last-child {
    font-size: 1.3vmin;
    text-align: center;
}

.AudioConfigWrapper > .TransparentButton {
    align-self: center;
    width: 2vmin;
    max-width: 2vmin;
    height: 2vmin;
    margin: 0;
    padding: 0;
    flex: none;
    border-radius: 100%;
    filter: brightness(180%);
}

.AutoAnswerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-basis: 18%;
    width: 100%;
    flex-shrink: 0;
    align-self: center;
    color: white;
    font-weight: 300;
    padding-bottom: 2%;
}

.AutoAnswerWrapper > .TransparentButton {
    height: 2vmin;
    flex-basis: 2vmin;
    flex-shrink: 1;
    flex-grow: 0;
    border-radius: 60%;
    filter: brightness(180%);
    margin-right: 3%;
}

.AutoAnswerWrapper > .TransparentButton.active {
    filter: brightness(180%) !important;
}

.AutoAnswerWrapper > * {
    align-self: center;
    font-weight: 300;
}

.MediaRefreshButton {
    flex-grow: 0 !important;
    flex-shrink: 0;
    flex-basis: 50% !important;
    height: 90%;
}

.MediaSettingsLabel {
    font-size: 2.5vmin !important;
    font-weight: 400;
}