
.InteractionButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.25vh;
    margin-bottom: 0.25vh;
    border-radius: calc(3px + 0.5vmin);
    border: 1px solid black;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.75vmin) black;
}

.InteractionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8vh;
    height: 3.8vh;
    margin-left: calc(10px + 1.5vmin);
    margin-right: calc(10px + 1.5vmin);
    border: 3px solid var(--very-very-light-background-color);
    border-radius: 100%;
    color: var(--very-very-light-background-color);
    box-shadow: 
        inset 0 0 calc(5px + 0.2vmin) black,
        0 0 calc(5px + 0.2vmin) black;
}

.InteractionButton:hover {
    filter: brightness(120%);
}

.InteractionButton:active {
    filter: brightness(90%);
}

.InteractionButton > div {
    text-shadow: 1px 0 3px var(--dark-background-color), 0 1px 3px var(--dark-background-color),
                 1px 1px 3px var(--dark-background-color), -1px 0 3px var(--dark-background-color),
                 0 -1px 3px var(--dark-background-color), -1px -1px 3px var(--dark-background-color);
    font-size: calc(10px + 2vmin);
}

.InteractionButton.BlueHaze.Active, .InteractionButton.BlueHaze:hover {
    box-shadow: 
        inset 0 0 calc(1px + 0.5vmin) var(--light-primary-color),
        inset 0 0 3px var(--very-light-background-color),
        0 0 calc(5px + 0.2vmin) var(--light-primary-color);
    border: 3px solid var(--very-light-primary-color);
    color: var(--bright-white-color);
}

.InteractionButton.BlueHaze.Active > div, .InteractionButton.BlueHaze:hover > div {
    text-shadow: 1px 0 3px var(--light-primary-color), 0 1px 3px var(--very-light-primary-color),
                 1px 1px 3px var(--light-primary-color), -1px 0 3px var(--very-light-primary-color),
                 0 -1px 3px var(--light-primary-color), -1px -1px 3px var(--light-primary-color);
}

.InteractionButton.RedHaze.Active, .InteractionButton.RedHaze:hover {
    box-shadow: 
        inset 0 0 calc(1px + 0.5vmin) var(--light-inactive-color),
        inset 0 0 3px var(--very-light-background-color),
        0 0 calc(5px + 0.2vmin) var(--light-inactive-color);
    border: 3px solid var(--very-light-inactive-color);
    color: var(--bright-white-color);
}

.InteractionButton.RedHaze.Active > div, .InteractionButton.RedHaze:hover > div {
    text-shadow: 1px 0 3px var(--light-inactive-color), 0 1px 3px var(--very-light-inactive-color),
                 1px 1px 3px var(--light-inactive-color), -1px 0 3px var(--very-light-inactive-color),
                 0 -1px 3px var(--light-inactive-color), -1px -1px 3px var(--light-inactive-color);
}

.InteractionButton.GreenHaze.Active, .InteractionButton.GreenHaze:hover {
    box-shadow: 
        inset 0 0 calc(1px + 0.5vmin) var(--light-active-color),
        inset 0 0 3px var(--very-light-background-color),
        0 0 calc(5px + 0.2vmin) var(--light-active-color);
    border: 3px solid var(--light-active-color);
    color: var(--bright-white-color);
}

.InteractionButton.GreenHaze.Active > div, .InteractionButton.GreenHaze:hover > div {
    text-shadow: 1px 0 3px var(--light-active-color), 0 1px 3px var(--light-active-color),
                 1px 1px 3px var(--light-active-color), -1px 0 3px var(--light-active-color),
                 0 -1px 3px var(--light-active-color), -1px -1px 3px var(--light-active-color);
}

