.CenterDivide {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-basis: 1.6vw;
    padding-left: 0.6vmin;
    padding-right: 0.6vmin;
    box-shadow: inset 0 0 calc(10px + 0.25vmin) var(--darker-background-color), 0 0 calc(3px + 0.15vmin) var(--darker-background-color);
}

.CenterDivideBody {
    flex-grow: 1;
    background-color: white;
    box-shadow: inset 0 0 calc(3px + 3.25vmin) var(--darker-background-color);
}

.CenterDivideBody.InScreening {
    filter: brightness(130%);
    background-image: linear-gradient(to bottom, var(--light-warning-color), white, var(--light-warning-color));
    box-shadow: inset 0 0 calc(10px + 5.25vmin) var(--warning-color), 0 0 calc(8px + 0.35vmin) var(--warning-color);
}

.CenterDivideBody.InCall {
    filter: brightness(130%);
    background-image: linear-gradient(to bottom, var(--very-light-primary-color), white, var(--very-light-primary-color));
    box-shadow: inset 0 0 calc(10px + 5.25vmin) var(--primary-color), 0 0 calc(8px + 0.35vmin) var(--primary-color);
}

.CenterDivideBody.OnAir {
    filter: brightness(130%);
    background-image: linear-gradient(to bottom, var(--light-inactive-color), white, var(--light-inactive-color));
    box-shadow: inset 0 0 calc(10px + 5.25vmin) var(--inactive-color), 0 0 calc(8px + 0.35vmin) var(--inactive-color);
}

.CenterDivideBody.OffAir {
    filter: brightness(130%);
    background-image: linear-gradient(to bottom, var(--light-active-color), white, var(--light-active-color));
    box-shadow: inset 0 0 calc(10px + 5.25vmin) var(--active-color), 0 0 calc(8px + 0.35vmin) var(--active-color);
}