
.ProfileImageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    padding-top: 5%;
    width: 96%;
    background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
    border-bottom: calc(1px + 0.10vmin) solid var(--primary-color);
    border-radius: 2%;
}

#ProfileImage {
    align-self: center;
    width: 66%;
    border-radius: 100%;
    border: calc(2px + 0.15vmin) solid black;
}

.ProfileImageButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 1.5vmin;
    padding-bottom: 1.5vmin;
}

.ProfileImageButtonWrapper > * {
    flex-grow: 1;
    text-align: center;
}

.ProfileImageButtonWrapper > * {
    padding: 0.5vmin;
}


.ProfileImageButtonWrapper > div, .ProfileImageButtonWrapper > label {
    padding: 0.75vmin;
    flex-basis: 35%;
    flex-grow: 0;
    flex-shrink: 0;
   
}