.LoginButton {
    outline: none;
    justify-content: space-evenly;
    flex-direction: row !important;
    align-self: center;
    margin: 2.5%;
    padding: 2%;
    width: 70%;
}

.LoginButton > div {
    align-self: center;
}

.LoginFormWrapper > form {
    flex-grow: 1;
    margin-bottom: 0%;
}

.LoginFormWrapper {
    margin-bottom: 4% !important;
}