.ConfigurationPanelWrapperOuter {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.ConfigurationPanelWrapperUpper{
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
    width: 100%;
}

.LocalVideoWrapperConfig {
    position: relative;
    flex-basis: 40%;
    align-self: center;
    height: 100%;
}

.LocalVideoMediaConfig {
    position: absolute;
    object-fit: contain;
    top: 4%;
    left: 2.5%;
    width: 96%;
    height: 92%;
    background-color: black;
    border: 1px solid black;
    border-radius: 0.3vmin;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.LocalVideoWrapperConfig .VideoFraming {
    position: absolute;
    top: 4%;
    left: 2.5%;
    width: 96%;
    height: 92%;
    object-fit: contain;
	filter: hue-rotate(220deg) brightness(250%);
}

.MediaConfigurationMenuPresentationWrapper {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 5;
}

.MediaConfigurationMenuPresentationWrapperOuter {
    align-self: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 3.5vmin;
    margin-right: 1%;
    flex-basis: 60%;
    height: 92%;
    border: 1px solid black;
    border-radius: 0.2vmin;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.MediaConfigurationMenuPresentationWrapperInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    flex-shrinK: 0;
    flex-basis: 15vh;
}

.MediaConfigurationMenuPresentationWrapperInner > div {
    font-size: 1.7vmin;
    font-weight: 500;
    color: var(--extremely-light-background-color);
}

.MediaConfigurationMenuPresentationWrapperInner > div.Active {
    color: var(--active-color);
}

.MediaConfigurationMenuPresentationWrapperInner > select {
    flex-grow: 1;
    width: 100%;
    margin: 1%;
	border: none;
    outline: none;
	box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(10px + 0.375vmin) black;
    background: 
        url('../../Assets/BackgroundNoise.png'),    
        linear-gradient(to bottom, var(--light-background-color), var(--lighter-background-color));
    color: var(--extremely-light-background-color);
    text-shadow: 0px 1px 0px rgba(0,0,0,0.3), 0px -1px 0px rgba(0,0,0,.7);
    font-weight: 300;
    font-size: 2.4vmin;
}

.MediaConfigurationMenuPresentationWrapperInner > select.Active {
    background: 
        url('../../Assets/BackgroundNoise.png'),    
        linear-gradient(to bottom, var(--light-primary-color), var(--primary-color));
}

.MediaConfigurationMenuButtonWrapper {
    display: flex;
    flex-basis: 20%;
    width: 50%;
    justify-content: space-evenly;
}

.MediaSettingsLabel {
    font-size: 2.5vmin !important;
    font-weight: 400;
}

.MediaConfigurationMessageWrapperOuter {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    flex-basis: 35%;
    width: 60%;
    border: 1px solid black;
    border-radius: 0.5vmin;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.MediaConfigurationMessageWrapperTop {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-basis: 70%;
    width: 100%;
}

.MediaConfigurationMessageWrapperTop > div {
    font-size: 2vmin;
    text-align: center;
    color: var(--extremely-light-background-color);
}

.MediaConfigurationMessageWrapperTop > div:first-child {
    font-size: 5vmin;
    color: var(--active-color);
}

.MediaConfigurationMessageWrapperBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    flex-basis: 15%;
    width: 50%;
}