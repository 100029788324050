.InformationPanelHeader {
    display: flex;
	align-items: stretch;
	align-self: center;
	flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex-basis: 4.0%;
    flex-shrink: 0;
	padding-bottom: 1.0vh;
	width: 94%;
}

