.MediaPermissionWrapperOuter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 3vmin;
}

.MediaPermissionWrapperInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    align-self: center;
    border-radius: 0.4vmin;
    border: 1px solid black;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(40px + 5.75vmin) black;
}

.MediaPermissionWrapperInner > div {
    text-align: center;
    align-self: center;
    filter: brightness(120%);
    color: var(--extremely-light-background-color);
    text-shadow: 
        0px 0px calc(3px + 1.5vmin) var(--extremely-light-background-color),
        0px 0px calc(1px + 0.3vmin) white;}

.MediaPermissionWrapperInner > .Primary {
    font-size: 10vmin;
}

.MediaPermissionWrapperInner > .Secondary {
    font-size: 3vmin;
}

.MediaPermissionWrapperInner > .Primary.Error {
    filter: brightness(120%);
    color: var(--light-inactive-color);
    text-shadow: 
        0px 0px calc(3px + 1.5vmin) var(--light-inactive-color),
        0px 0px calc(1px + 0.3vmin) white;
}