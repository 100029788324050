
.Main {
    display: flex;
    flex-direction: row;
	/*background-image: linear-gradient(to right bottom, var(--light-background-color), var(--lighter-background-color));
    box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
        inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);*/
}

.LeftPanelWrapper {
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 96vh;
}

.TransparentButton:first-child {
    border-left: calc(1px + 0.15vmin) solid var(--darker-background-color);
}

.TransparentButton:last-child {
	border-right: calc(1px + 0.15vmin) solid var(--darker-background-color);
}

.TransparentButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	text-align: center;
	flex: 1;
	line-height: 100%;
    font-weight: 400;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 0.15vmin;
    background: 
        url('../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--extremely-light-background-color), var(--very-light-background-color));
        /*radial-gradient(white, var(--extremely-light-background-color) 50%);*/
    background-repeat: repeat;
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--normal-background-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 rgba(0,0,0,0.25),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 rgba(0,0,0,0.15),
        calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.15vmin) black;
	border-top: calc(1px + 0.15vmin) solid var(--darker-background-color);
	border-bottom: calc(1px + 0.15vmin) solid var(--darker-background-color);
	border-left: calc(1px + 0.03vmin) solid var(--darker-background-color);
    border-right: calc(1px + 0.03vmin) solid var(--darker-background-color);
    color: var(--dark-background-color);
    text-shadow: 0px 1px 0px rgba(255,255,255,0.3), 0px -1px 0px rgba(0,0,0,.7);
	transition: 0.5s;
}

.TransparentButton.Static {
    transition: 0.25s;
}

.TransparentButton.active, .TransparentButton:active {
    filter: brightness(130%) !important;
    border-color: var(--dark-background-color);
    box-shadow:
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 rgba(0,0,0,0.25),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 rgba(0,0,0,0.25),
        inset 0 calc(2px + 0.30vmin) calc(10px + 0.75vmin) 0 var(--dark-background-color) !important;
}

.TransparentButton:hover {
    filter: brightness(110%);
}

.TransparentButton.BlueHaze.Static, .TransparentButton.BlueHaze:hover {
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--light-primary-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--very-light-primary-color));
}

.TransparentButton.BlueHaze.active, .TransparentButton.BlueHaze:active {
    background: 
        url('../../Assets/BackgroundNoise.png'),
        radial-gradient(var(--light-primary-color) 25%, var(--light-primary-color));
}

.TransparentButton.RedHaze.Static, .TransparentButton.RedHaze:hover {
    box-shadow:
        inset 0 4px calc(6px + 4vmin) var(--light-inactive-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--very-light-inactive-color));
}

.TransparentButton.RedHaze.active, .TransparentButton.RedHaze:active {
    background: 
        url('../../Assets/BackgroundNoise.png'),
        radial-gradient(var(--very-light-inactive-color), var(--light-inactive-color));
}

.TransparentButton.GreenHaze.Static, .TransparentButton.GreenHaze:hover {
    box-shadow:
        inset 0 4px calc(6px + 4vmin) var(--light-active-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color),
        calc(1px + 0.1vmin) calc(1px + 0.1vmin) calc(5px + 0.2vmin) black;
    background:
        url('../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--light-active-color));
}

.TransparentButton.GreenHaze.active, .TransparentButton.GreenHaze:active {
    background: 
        url('../../Assets/BackgroundNoise.png'),
        radial-gradient(var(--light-active-color), var(--light-active-color));
}

.TransparentButton.inactive {
    filter: brightness(50%) !important;
    box-shadow: 
        inset 0 4px calc(6px + 4vmin) var(--normal-background-color),
        inset calc(3px + 0.10vmin) calc(1px + 0.05vmin) 0 var(--very-light-background-color),
        inset calc(-3px - 0.10vmin) calc(-3px - 0.10vmin) 0 var(--very-light-background-color) !important;
    background: 
        url('../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, white, var(--extremely-light-background-color), var(--very-light-background-color)) !important;
}


.LeftPanelWrapperOuter {
    min-height: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 18vw;
    min-width: 300px;
    height: 100vh;
    padding-left: 0.4vmin;
    padding-top: 0.5vmin;
}

.LeftPanelContentWrapperInner {
    flex-grow: 1;
    background-color: pink;
}

.RightPanelWrapper {
    flex-grow: 1;
    flex-shrink: 1;
    /*flex-basis: 82vw;*/
    width: 82vw;
    padding-right: 1.0vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.RightPanelHeader {
    flex-basis: 5%;
}

.RightPanelBody {
    position: relative;
    /*flex-basis: 89%;*/
    height: 89vh;
    width: 100%;
}

.RightPanelFooter {
    justify-content: flex-end;
    display: flex;
    flex-basis: 6%;
}

.MainMobile {
    overflow: hidden;
    display: flex;
    width: 100vw;
    height: 100vh;
}

.BodyMobileWrapper {
    flex-grow: 1;
    flex-basis: 100%;
    background-color: green;
    display: flex;
    flex-direction: column;
}

.HorizontalRule {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 0.25%;
    border-radius: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom right, var(--light-primary-color), var(--primary-color));
}

.FooterFiller {
    flex-basis: 3%;
    margin: 2.5%;
}

.IsolatedInputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 8%;
}

.IsolatedInputWrapper input:first {
    margin-top: 0;
}

.IsolatedInputWrapper input {
    align-self: center;
    flex-basis: 4%;
    margin-top: 2%;
    padding: 5%;
    width: 96%;
    background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
    background-color: black;
    color: var(--extremely-light-background-color);
    font-weight: 300;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
    border: calc(1px + 0.1vmin) solid black;
    border-bottom: calc(1px + 0.10vmin) solid var(--primary-color);
    border-radius: 2%;
    padding-left: 2%;
}

.IsolatedInputWrapper input::placeholder {
    opacity: 1;
}

.IsolatedInputWrapper input:focus::placeholder {
    color: var(--active-color);
    opacity: 1;
}

.IsolatedInputWrapper input:-webkit-autofill {
	-webkit-text-fill-color: var(--white-color) !important;
	box-shadow: 0 0 0px 100vmax var(--dark-background-color) inset !important;
	color: inherit !important;
}

.IndentHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.IndentHeaderWrapper > div {
    align-self: center;
    flex-basis: 3%;
    color: transparent; 
    font-size: 5vmin;
    text-align: center;
    background-color: black;
    text-shadow: 0px 2px 2px rgba(200,200,200,0.15);
    background-clip: text;
    width: 96%;
}

.IndentHeaderWrapper > .Error {
    font-size: 1.5vmin;
    flex-basis: 4%;
    font-weight: 500;
    background-image: linear-gradient(to bottom right, var(--light-inactive-color), var(--inactive-color));
}

.IndentHeaderWrapper > .Message {
    font-size: 1.5vmin;
    flex-basis: 4%;
    font-weight: 500;
    background-image: linear-gradient(to bottom right, var(--light-active-color), var(--active-color));
}

.DisplayNameIndicator {
    text-align: right !important;
    font-size: 4vmin !important;
    font-weight: 400;
}

.FlexColumnWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
}

.BodyFiller {
    flex-grow: 1;
    border-radius: 1%;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.MediaSettingsFiller {
    flex-basis: 40%;
}

.IsolatedButton {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-basis: 3%;
    width: 75%;
    margin: 2.5%;
    align-self: center;
    color: var(--extremely-light-background-color);
    transition: 0.5s;
    border: 2px solid black;
    border-radius: calc(2px + 0.25vmin);
    background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
}

.IsolatedButton:hover {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--bright-white-color), 0 0 calc(15px + 0.7vmin) var(--very-light-primary-color);
}

.IsolatedButton:active {
    box-shadow: inset 0 0 calc(20px + 0.45vmin) var(--dark-background-color);
    border: 3px solid black;
	filter: brightness(75%);
}