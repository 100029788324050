.ShowButtonWrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-basis: 3%;
    width: 75%;
    margin: 2.5%;
}

.JoinShowLegal {
    color: var(--very-very-light-background-color);
    text-align: center;
    margin: 0.5vmin;
}

.JoinShowBodyFiller {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}