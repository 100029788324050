.ContactsDirectoryWrapper {
    min-height: 0;
    display: flex;
    align-self: center;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 40%;
    width: 100%;
}

.UserCard {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 0.5vmin;
}

.UserCardContent {
    display: flex;
    flex-direction: row;
}

.UserCardContent > div:first-child {
    display: flex;
    justify-content: center;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
}

.ContactAvatar {
    align-self: center;
    width: 80%;
    height: auto;
    border-radius: 100% 100%;
}

.UserCardContent > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-basis: 75%;
    flex-shrinK: 0;
    color: white;
}

.UserCardContent > div:last-child div {
    font-size: 1.0vmin;
    font-weight: 300;
}

.UserList {
    min-height: 0;
    overflow-y: scroll;
    align-self: center;
    flex-basis: 83%;
    width: 98%;
    border-radius: 0.35vmin;
    background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
    border: 1px solid black;
}

.UserListWrapper {
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.UserCard {
    background-repeat: repeat;
}

.UserCard:hover {
    border: 1px solid var(--active-color);
}

.UserCard:nth-child(even) {
    background:
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, var(--dark-background-color), var(--very-dark-background-color));
}

.UserCard:nth-child(odd) {
    background:
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, var(--normal-background-color), var(--darker-background-color));
}

.UserCard.Selected {
    border: calc(2px + 0.15vmin) solid var(--primary-color);
}


.Online .ContactAvatar {
    border: 2px solid var(--active-color);
}

.Offline .ContactAvatar {
    border: 2px solid var(--inactive-color);
}

.ContactsTabWrapper {
    display: flex;
    align-self: center;
    justify-content: space-evenly;
    flex-basis: 5%;
    width: 98%;
}

.ContactsDirectoryWrapper input {
    flex-basis: 5%;
    align-self: center;
    text-align: center;
    width: 98%;
	appearance: none;
    box-sizing: border-box;
    border-radius: 0.35vmin;
    padding: 0.70vmin 0.5vmin 0.70vmin 0.5vmin;
    border: 1px solid black;;
    text-align: left;
	font-size: 1.3vmin;
	color: var(--white-color);
	background-color: var(--very-dark-background-color);
	box-shadow: inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
}

.ContactsButtonWrapper {
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid var(--primary-color);
    margin-top: 1%;
    padding-top: 1%;
}

.ContactsButtonWrapper > div {
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.3vmin;
}

.ContactsButtonWrapper > .inactive {
    background-image: linear-gradient(to top, var(--light-background-color), var(--lighter-background-color));
    color: var(--darker-background-color);
}

.ContactsButtonWrapper > .active {
    background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
    
}

/*.ContactsButtonWrapper > .remove {
    background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
}*/

.ContactsButtonWrapper > .add, .ContactsButtonWrapper > .remove {
    background-image: linear-gradient(to top, var(--very-light-background-color), var(--light-background-color));
    
}