.RemoteVideo {
    position: absolute;
    top: 0.5vmin;
    left: 0.5vmin;
    width: calc(100% - 1vmin);
    height: calc(100% - 1vmin);
    object-fit: contain;
    padding: 0.5vmin;
    z-index: 1;
}
.RemoteIFrame {
    position: absolute;
    top: 0.5vmin;
    left: 0.5vmin;
    width: calc(100% - 1vmin);
    height: calc(100% - 1vmin);
    padding: 0.5vmin;
    z-index: 2;
}

.RemoteVideoFullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    object-fit: contain;
    background-color: black;
}

.RemoteVideoWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0.5vmin;
    border: 1px solid black;
    background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
    color: var(--extremely-light-background-color);
    font-weight: 300;
    z-index: 2;
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.ShowStateWrapper.Blocked > div {
    filter: brightness(120%);
    font-size: 12vw;
    color: var(--light-inactive-color);
    text-shadow: 
        0px 0px calc(3px + 1.5vmin) var(--light-inactive-color),
        0px 0px calc(1px + 0.3vmin) white;
}

.ShowStateWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 3;
    border: 1px solid black;
}

.ShowStateWrapper > div {
    align-self: center;
    font-size: 10vmin;
    text-align: center;
    font-weight: 500;
    color: transparent; 
    background-color: black;
    text-shadow: 0px 2px 2px rgba(200,200,200,0.15);
    background-clip: text;
}

.ShowStateWrapperDynamic {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 3;
    border: 1px solid black;
}

.ShowStateWrapperDynamic.Embed {
    opacity: 0;
    transition: 10s;
    background-color: rgba(0,0,0,0.5);
    animation: StateIndicator 3.0s linear;
}

.ShowStateWrapperDynamic.Embed:hover {
}

@keyframes StateIndicator {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ColumnDivide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    flex-basis: 10%;
    height: 2.5%;
    padding-top: 0.5vmin;
    padding-bottom: 0.5vmin;
    box-shadow: 0 calc(1px + 0.10vmin) calc(1px + 0.25vmin) black;
}

.ColumnSpacer {
    flex-basis: 10%;
    align-self: center;
    height: 5%;
}

.ColumnDivide > div {
    align-self: center;
    width: 100%;
    flex-grow: 1;
    background-image: linear-gradient(to bottom, var(--very-light-background-color), var(--very-very-light-background-color), var(--very-light-background-color));
    box-shadow: inset 0 0 calc(3px + 3.25vmin) var(--darker-background-color);
}

.ColumnDivide.active > div {
    background-image: linear-gradient(to bottom, var(--extremely-light-background-color), white, var(--extremely-light-background-color));
    box-shadow: 
        inset 0 0 calc(2px + 0.5vmin) black,
        0 0 calc(1px + 0.5vmin) var(--extremely-light-background-color);
}

.ShowStateWrapperColumnOuter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    flex-basis: 15%;
    height: 40%;
    border-radius: 2%;
    border: 2px solid black;
    padding: 1.0vmin;
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.ShowStateWrapperColumnOuter.active {
    height: 50%;
    flex-basis: 20%;
}

.ShowStateWrapperColumnInner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    flex-grow: 1;
    width: 100%;
    border-radius: 2%;
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, var(--normal-background-color), var(--very-dark-background-color));
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.ShowStateWrapperColumnInner > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    color: var(--very-light-background-color);
    /*color: transparent;
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, var(--very-light-background-color), var(--light-background-color));
    background-clip: text;*/
    padding: 0.5vw;
}

.ShowStateWrapperColumnInner.active > div {
    filter: brightness(110%);
    background: none;
    color: var(--very-light-warning-color);
    text-shadow: 
        0px 0px calc(5px + 1.0vmin) var(--warning-color),
        0px 0px calc(2px + 0.15vmin) white;
}

.ShowStateWrapperColumnInner.dim > div {
    filter: brightness(110%);
    background: none;
    color: var(--very-very-light-background-color);
    text-shadow: 
        0px 0px calc(1px + 0.25vmin) var(--extremely-light-background-color);
}

.ShowStateWrapperColumnInner > .Header {
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
}

.ShowStateWrapperColumnInner > .Header > div:first-child {
    font-size: 2vw;
    font-weight: 400;
}

.ShowStateWrapperColumnInner > .Header > div:last-child {
    font-size: 1.0vw;
}


.ShowStateWrapperColumnInner > .Body {
    flex-basis: 70%;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
}

.ShowStateWrapperColumnInner > .Body > div {
    font-size: 1vw;
}

.ShowStateWrapperColumnInner > .Body > .Completed {
    font-size: 1.7vw;
}

.ShowStateWrapperColumnInner > .Body > .PositionIndicator {
    flex-basis: 60%;
    font-size: 9vmin;
    font-weight: 500;
}

@keyframes OnAirIndicator {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes OffAirIndicator {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ShowStateWrapper.OffAir {
    opacity: 0;
    background-image: radial-gradient(rgba(8, 148, 57, 0.4), rgba(9, 207, 82, 0.5));
    box-shadow: inset 0 0 100vw black;
    animation: OffAirIndicator 2.5s linear;
}

.ShowStateWrapper.OnAir {
    opacity: 0;
    background-image: radial-gradient(rgba(232, 29, 6, 0.4), rgba(207, 29, 9, 0.5));
    box-shadow: inset 0 0 100vw black;
    animation: OnAirIndicator 2.5s linear;
}

.ShowStateWrapper.OnAir > div, .ShowStateWrapper.OffAir > div {
    width: 100%;
    text-align: center;
    background-image: radial-gradient(rgba(50,50,50,0.4), rgba(30,30,30, 0.3), rgba(20,20,20,0), rgba(0,0,0,0));
    font-size: 20vmin;
    font-weight: 700;
    opacity: 0.9;
    color: var(--extremely-light-background-color);
    text-shadow:
        5px 0px black,
        -5px 0px black,
        0px 5px black,
        0px -5px black,
        5px 5px black,
        -5px 5px black,
        5px -5px black,
        -5px -5px black;
}